import React, { useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import contactIcon from '@images/icons/contact-icon.svg'
import Button from '@elements/Button'
import { useBreakpoint } from '@hooks/useBreakpoint'
// import Modal from '@elements/form/FormModal'
import EmfImage from '@elements/EmfImage'

const SectionWithImage = styled.section`
	${tw`relative max-h-[1100px] flex align-top mb-[77px] xl:mb-[240px] 2xl:mb-[200px] xl:min-h-[640px] 2xl:min-h-[780px]`};
`
const Wrapper = styled.div`
	${tw`relative flex flex-col w-full md:pb-0 bg-primaryDark md:w-1/2`};
`
const TextWrapper = styled.div`
	${tw`relative z-10 flex flex-col w-full pl-5 text-white md:pl-16 lg:pl-24 bg-primaryDark md:w-1/2`}
	@media screen and (max-width: 767px) {
		padding-bottom: ${({ textHeight }) => (textHeight ? `${Math.max(textHeight, 320)}px` : '320px')};
	}
	@media screen and (min-width: 768px) {
		padding-bottom: ${({ textHeight }) => (textHeight ? `calc(11vh + ${textHeight}px)` : '100px')};
	}
`
const ContactIconWithImage = styled.img`
	${tw`absolute z-40 h-[67px] bottom-[216px] md:absolute md:bottom-[39%] md:left-[-115px] lg:bottom-[44%] xl:h-[100px] xl:bottom-[41%] 2xl:bottom-[48%] xl:left-[-170px] 3xl:left-[-226px] 3xl:h-[132px]`};
	@media screen and (max-width: 768px) {
		right: calc(66% - 34px);
	}
`
const StyledImage = styled(EmfImage)`
	${tw`absolute z-10 h-[325px] self-end right-0 w-2/3 md:top-[56%] lg:top-[52%] 2xl:top-[43%] md:h-auto md:w-full [img]:max-h-[679px]`}
	@media screen and (max-width: 1023px) {
		bottom: -77px;
	}

	img {
		${tw`object-cover`}
		object-position: 100% calc(50% + 0px) !important;
	}
`
const StyledDescription = styled.p`
	${tw`pr-5 md:max-w-[min(35vw, 950px)] font-futuraBook text-white italic tracking-1 md:tracking-2 leading-[20px] text-[14px] pt-9`};
`
const CalculatedHeightWrap = styled.div`
	padding-bottom: ${({ textHeight }) => (textHeight ? `${Math.max(140, textHeight)}px` : '140px')};
	@media screen and (min-width: 768px) {
		padding-bottom: ${({ textHeight }) => (textHeight ? `${Math.max(150, textHeight)}px` : '150px')};
	}
`

const StyledButton = tw(Button)`mt-12 [>span]:pl-6`
const StyledHeadline = styled.h2`
	${tw`font-futuraExtra text-white font-extrabold tracking-1 md:tracking-3 uppercase max-w-[340px] md:w-[min-content] md:max-w-[480px] xl:max-w-[660px] 3xl:max-w-[1000px]
text-[50px] leading-[50px] xl:text-[70px] xl:leading-[70px] 3xl:text-[100px] 3xl:leading-[100px] pt-10 md:pt-24 lg:pt-36`}
	@media screen and (max-width: 420px) {
		font-size: ${({ label }) => {
			if (label.split(' ').some((item) => item.length > 9)) {
				return '44px'
			}
			return '50px'
		}};
		line-break: ${({ label }) => {
			if (label.split(' ').some((item) => item.length > 9)) {
				return '44px'
			}
			return '50px'
		}};
	}
`
const WhiteStripe = styled.div`
	${tw`absolute bottom-0 left-0 w-full h-full bg-white`}
	clip-path: polygon(0 75%, 100% 87%, 100% 100%, 0% 100%);
`
const DarkStripe = styled.div`
	${tw`absolute bottom-0 left-0 w-full h-full bg-greyStripe`}
	clip-path: polygon(0 81%, 100% 92%, 100% 100%, 0% 100%);
`
//version without Image
const Section = tw.section`relative bg-primaryDark w-full flex flex-col align-top pl-5 text-white md:pl-16 lg:pl-24 xl:min-h-[640px] fhd:min-h-[780px]`
const ContactIcon = tw.img`inline-block mt-6 ml-40 xs:ml-64 md:ml-[8vw] md:mt-52 3xl:mt-60 xl:ml-[8vw] z-40 h-[67px] md:h-[80px] xl:h-[100px] 3xl:h-[132px]`

const Stripes = () => (
	<>
		<WhiteStripe />
		<DarkStripe />
	</>
)

const WpAcfBluesectionBlock = ({ data, locale }) => {
	const { anchor } = data.attributes
	const { buttonLabel, sectionType, headline, text, image, formId } = data.modulesBlueSection
	const { isMobile } = useBreakpoint()
	const withImage = sectionType === 'withImage'
	const [modalOpen, setModalOpen] = useState(false)
	const toggleModal = () => setModalOpen((prev) => !prev)
	const [textHeight, setTextHeight] = useState()
	const textRef = useRef()
	const handleTextHeight = () => !!textRef.current && setTextHeight(textRef.current.clientHeight)

	useEffect(() => {
		if (!!textRef.current) {
			handleTextHeight()
		}
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleTextHeight)
			return () => {
				window.removeEventListener('resize', handleTextHeight)
			}
		}
	}, [])

	const SectionButton = () => {
		if (buttonLabel) {
			return (
				<StyledButton
					label={buttonLabel}
					onClick={(event) => {
						event.preventDefault()
						!!formId && toggleModal()
					}}
					variant='light'
					className={!withImage && `lg:mb-16 fhd:mb-32 4xl:mb-40`}
				/>
			)
		}
		return null
	}

	const ContactIconImage = () => (
		<div tw='flex flex-col'>
			{withImage && <ContactIconWithImage src={contactIcon} alt='' />}
			<StyledImage src={image?.localFile} textHeight={textHeight} altText={image?.altText} />
		</div>
	)

	const isSSR = typeof window === 'undefined'
	const LazyModal = () => {
		const Modal = React.lazy(() => import('@elements/form/FormModal'))
		return <Modal modalOpen={modalOpen} handleClose={toggleModal} formId={formId} locale={locale} />
	}

	if (!withImage) {
		return (
			<Section id={anchor !== '' ? anchor : 'contact-section'}>
				<CalculatedHeightWrap className='md:flex' textHeight={textHeight}>
					<div className='inline-block'>
						{headline && <StyledHeadline label={headline}>{headline}</StyledHeadline>}
						{text && (
							<StyledDescription ref={textRef} textHeight={textHeight} className='md:max-w-[min(40vw, 950px)]'>
								{text}
							</StyledDescription>
						)}
						<SectionButton />
					</div>
					<ContactIcon src={contactIcon} />
				</CalculatedHeightWrap>
				<Stripes />
				{!isSSR && <React.Suspense fallback={null}>{!!formId && modalOpen && <LazyModal />}</React.Suspense>}
			</Section>
		)
	}
	return (
		<SectionWithImage id={anchor !== '' ? anchor : 'contact-section'}>
			<TextWrapper textHeight={textHeight}>
				{headline && <StyledHeadline label={headline}>{headline}</StyledHeadline>}
				{text && <StyledDescription ref={textRef}>{text}</StyledDescription>}
				<SectionButton />
				{isMobile && <ContactIconImage />}
				<Stripes />
			</TextWrapper>
			{!isMobile && (
				<Wrapper>
					<ContactIconImage />
				</Wrapper>
			)}
			{!isSSR && <React.Suspense fallback={null}>{!!formId && modalOpen && <LazyModal />}</React.Suspense>}
		</SectionWithImage>
	)
}

export default WpAcfBluesectionBlock
